@import '../base/colors';
#header {
  background-color: $header-background;
  height: 48px;
  overflow: hidden;
  color: #fff;
  display: flex;
}

.title {
  // text-transform: capitalize;
  font-weight: 200;
  font-size: 18pt;
  color: $header-foreground;
  display: block;
  float: left;
  // margin: 0 0 0 20px;
  line-height: 48px;
}

#mobile_title {
  font-size: 12pt;
  margin: 0px 0px 0px 19px;
  font-weight: 600;
}

.hamburger-icon-container {
  font-size: 14pt;
  height: fit-content;
  width: fit-content;
  color: $header-foreground;
  line-height: 42px;
  position: relative;
  top: 2px;
  left: 1px;
  float: left;
  margin-left: 14px;

  &.margin-fix {
    margin-right: 20px;
  }

  &:focus {
    outline: 1px dashed #fff;
    background: rgba(0, 0, 0, 0.1);
  }
}

.avatar-container {
  margin: auto 2px auto auto;
}

.notification-container {
  margin: 10px;

  &:focus {
    outline: 1px dashed #fff;
    background: rgba(0, 0, 0, 0.1);
  }
}

.skip-mainContent {
  position: absolute;
  width: 1px;
  height: 1px;
  z-index: -999;
}

.skip-mainContent:focus,
.skip-mainContent:active {
  color: #fff;
  text-decoration: underline;
  width: auto;
  height: auto;
  overflow: auto;
  border-radius: 1px;
  text-align: center;
  font-size: medium;
  z-index: 999;
}
