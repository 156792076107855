$progress-color: #26873a;
$header-background: #26873a; /*#eb5f52;*/
$header-background-hover: #26873a; /*#d54e42;*/
$header-subtle: #26873a; /*#b9443a;*/
$header-foreground: #ffffff;

$sidebar-background: #231f20;
$sidebar-foreground: #fff;
$sidebar-subtle-background: #506e54;
$sidebar-subtle-foreground: #fff;
$sidebar-active-background: #2d8a3a;
$sidebar-active-foreground: #ffffff;
$sidebar-active-action: #2d8a3a;

$content-background: #ffffff;
$content-foreground: #222222;
$base-font-size: 10.5pt;
$border-color: #a7a9ac;
$border-color-1: #414042;
$table-text: #696969;
$anchor-text: #006cd8;

$color-dark: #231f20;
$color-gray: #ededed;
$color-medium-gray: #c4c4c4;
$text-color-dark: #333333;
$text-color-default: #000000;
$text-danger: #d62a2a;
$danger: #a90000;

$color-background: #ffffff;
$color-input-primary: #0078d4;

$color-text-secondary: #707070;
$color-text-tertiary: #4f4f4f;

$compliance-ppa-border-color: #21965333;
$compliance-qa-border-color: #ffea2f33;

$divider-color: #e6e6e6;
