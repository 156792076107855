@import '../base/fonts';
@import '../base/colors';

.btn {
  border-radius: 0;
  box-shadow: none;
  text-shadow: none;
  font-weight: 400;
  font-size: 12pt;
  font-family: $font;
  padding: 0 12px;
  min-width: 90px;
  height: 30px;
  line-height: 28px;

  &:active,
  &:hover,
  &:focus {
    box-shadow: none;
    text-shadow: none;
    border-radius: 0;
  }

  &:focus {
    outline: dotted #333;
  }

  .icon {
    font-weight: 500;
  }

  &.btn-dark {
    background-color: $color-dark;
    color: #ffffff;
    /*border: 1px solid $color-dark;*/
    &.btn-sm {
      height: 30px !important;
      padding: 3px 10px !important;
    }

    &:not([disabled]):hover {
      background-color: transparent;
      border: 1px solid $color-dark;
      color: $color-dark;
    }
  }

  &.btn-light {
    background-color: #ffffff;
    border: 1px solid $color-dark;
    color: $text-color-dark;

    &:not([disabled]):hover {
      background-color: $color-dark;
      color: #ffffff;
    }
  }

  &.btn-dark-light {
    background-color: $color-dark;
    border: 1px solid #ffffff;
    color: #ffffff;
  }

  &.btn-ico {
    background-color: $color-gray;
    color: $text-color-dark;
    width: 35px;
    padding-left: 10px !important;
  }

  &.btn-icon {
    background-color: transparent;
    color: inherit;
    padding: 0;
    margin: 0;
    min-width: auto;
  }

  &.btn-report {
    background-color: $color-dark;
    font-size: 10px;
    margin-top: -4px;
    border: solid #ffffff;
    color: #ffffff;
    padding: 0;
    height: 144%;
    line-height: 2%;
    border-bottom-width: thin;
    border-left-width: thin;
    border-right-width: thin;
    border-top-width: thin;
  }

  &.dropdown-toggle {
    border: none;
  }
}

.mat-icon-container {
  background: unset;
  border: unset;
  height: max-content;
  padding: unset;

  &:focus {
    outline: none;
  }
}

.action-btn {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: $color-input-primary;
  color: $color-background;
  filter: drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.25));
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0px rgba(0, 0, 0, 0.1) inset;
}
