@import '../base/colors';

.tabs {
  height: max-content;
  display: flex;
  width: 100%;
  box-shadow: 0px 2px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
}

.tile-tabset {
  width: 100%;
  text-align: center;
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  font-size: 3.2vw;
}

.tab {
  padding: 16px;
  &__header {
    font-size: 16px;
    font-weight: bold;
  }
}
.nav-tabs {
  display: flex;
}

.nav-tabs .nav-item {
  flex-grow: 0;
  flex-basis: 50%;

  &:first-child {
    border-right: 1px solid $color-gray;
  }
}

.nav-tabs .nav-link {
  border-radius: 0;
  color: #707070;

  &:focus {
    outline: 1px dashed $text-color-default;
  }
}

.nav-tabs a.nav-link.active,
.nav-tabs .show > a.nav-link {
  background-color: #fff;
  color: black;
  border-bottom: 2px solid $color-input-primary;
  opacity: 1;
  height: 100%;

  &:focus {
    outline: 1px dashed $text-color-default;
  }
}
