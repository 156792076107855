@import './mixins';
.icon-container {
  outline: none;
  border: 0;
  width: auto;
  display: block;
  float: left;
  min-width: 0;
  min-height: 0;
  font-size: em(20px);
  padding: 0 14px;
  background-color: transparent;

  span {
    margin-left: 10px;
    position: relative;
    top: -3px; /* fix for improper positioning */
  }

  @include focus-states();
}

.icon {
  font-family: 'SegoeUI MDL2 Assets';
  font-weight: normal;
  font-style: normal;
  position: relative;
  top: 0;
  display: inline;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.icon-notification:before {
    content: '\E783';
  }

  &.icon-back:before {
    content: '\E112';
  }

  &.icon-unlock:before {
    content: '\E785';
  }

  &.icon-close:before {
    content: '\E10A';
  }

  &.icon-hamburger:before {
    content: '\E700';
  }

  &.icon-search:before {
    content: '\E1A3';
  }

  &.icon-user:before {
    content: '\E2AF';
  }

  &.icon-settings:before {
    content: '\E713';
  }

  &.icon-setting:before {
    content: '\E115';
  }

  &.icon-chevron-up:before {
    content: '\E09C';
  }

  &.icon-chevron-down:before {
    content: '\E09D';
  }

  &.icon-chevron-right:before {
    content: '\E00F';
  }

  &.icon-chevron-left:before {
    content: '\E00E';
  }

  &.icon-star:before {
    content: '\E1CE';
  }

  &.icon-star-filled:before {
    content: '\E1CF';
  }

  &.icon-basket:before {
    content: '\E821';
  }

  &.icon-pipe:before {
    content: '\E17B';
  }

  &.icon-accept:before {
    content: '\E001';
  }

  &.icon-pipe-health:before {
    content: '\E17A';
  }

  &.icon-bulb:before {
    content: '\E82F';
  }

  &.icon-alert:before {
    content: '\E1DE';
  }

  &.icon-eye:before {
    content: '\E052';
  }

  &.icon-square:before {
    content: '\E25E';
  }

  &.icon-circle:before {
    content: '\EC14';
  }

  &.icon-circle-outline:before {
    content: '\EA3A';
  }

  &.icon-home:before {
    content: '\E10F';
  }

  &.icon-apps:before {
    content: '\E74C';
  }

  &.icon-pointer:before {
    content: '\E1E3';
  }

  &.icon-next:before {
    content: '\E0AB';
  }

  &.icon-devices:before {
    content: '\E70C';
  }

  &.icon-clock:before {
    content: '\E823';
  }

  &.icon-collapse:before {
    content: '\E92C';
  }

  &.icon-book:before {
    content: '\E82D';
  }

  &.icon-calender:before {
    content: '\E163';
  }

  &.icon-plus:before {
    content: '\E109';
  }

  &.icon-resize:before {
    content: '\E73F';
  }

  &.icon-glyph-81:before {
    content: '\E143';
  }

  &.icon-glyph-70:before {
    content: '\E138';
  }

  &.icon-help:before {
    content: '\E11B';
  }

  &.icon-arrow-bottom-right:before {
    content: '\E741';
  }

  &.icon-file-outline-up:before {
    content: '\E8E5';
  }

  &.icon-copy:before {
    content: '\E8C8';
  }

  &.icon-calculator:before {
    content: '\E8EF';
  }

  &.icon-shuffle:before {
    content: '\E8B1';
  }

  &.icon-account-box:before {
    content: '\E187';
  }

  &.icon-emoji:before {
    content: '\E76E';
  }

  &.icon-info:before {
    content: '\E946';
  }

  &.icon-email-forward:before {
    content: '\E89C';
  }

  &.icon-file-outline:before {
    content: '\E160';
  }

  &.icon-file-multiple:before {
    content: '\E16F';
  }

  &.icon-download:before {
    content: '\E118';
  }

  &.icon-account-outline:before {
    content: '\E13D';
  }

  &.icon-gylph-74:before {
    content: '\E13C';
  }

  &.icon-account-switch:before {
    content: '\E748';
  }

  &.icon-dots-horizontal:before {
    content: '\E10C';
  }

  &.icon-account-multiple:before {
    content: '\E125';
  }

  &.icon-checkbox-blank:before {
    content: '\E003';
  }

  &.icon-check:before {
    content: '\E001';
  }

  &.icon-box-full:before {
    content: '\E747';
  }

  &.icon-filter-close:before {
    content: '\EA39';
  }

  &.icon-full-screen:before {
    content: '\E923';
  }

  &.icon-arrow-up:before {
    content: '\E110';
  }

  &.icon-arrow-down:before {
    content: '\E1FD';
  }

  &.icon-filter:before {
    content: '\E16E';
  }

  &.icon-rectangle:before {
    content: '\E20B';
  }

  &.icon-circle-lg:before {
    content: '\EA3B';
  }

  &.icon-percent:before {
    content: '\E94C';
  }

  &.icon-grid:before {
    content: '\E80A';
  }

  &.icon-minus:before {
    content: '\E949';
  }

  &.icon-video:before {
    content: '\E25D';
  }

  &.icon-doc:before {
    content: '\E2B4';
  }

  &.icon-list-expand:before {
    content: '\E169';
  }

  &.icon-list-collapse:before {
    content: '\E16A';
  }

  &.icon-repeat:before {
    content: '\E1CD';
  }

  &.icon-refresh:before {
    content: '\E117';
  }

  &.icon-bell-outline:before {
    content: '\EA8F';
  }

  &.icon-tip:before {
    content: '\EB50';
  }

  &.icon-warning::before {
    content: '\E783';
  }

  &.icon-pin-left:before {
    content: '\E141';
  }

  &.icon-delete:before {
    content: '\E107';
  }

  &.icon-edit:before {
    content: '\E104';
  }

  &.icon-camera:before {
    content: '\E114';
  }
  &.icon-logout:before {
    content: '\E7E8';
  }

  &.icon-logout:before {
    content: '\E7E8';
  }

  &.icon-trainingClicked:before {
    content: '\E73E';
    font-size: 13.5px;
  }

  &.icon-openInNewWindow:before {
    content: '\E8A7';
    font-size: 13.5px;
  }

  &.icon-chatBot:before {
    content: '\E25C'; //E8F2
  }
}

.icon-ms {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ms_icon_family' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.icon-dashboard:before {
    content: '\e965';
  }
  &.icon-treeorg:before {
    content: '\e966';
  }
  &.icon-expand:before {
    content: '\e967';
  }
  &.icon-menu:before {
    content: '\e968';
  }
  &.icon-drag:before {
    content: '\e969';
  }
  &.icon-information:before {
    content: '\e96b';
  }
  &.icon-information-hover:before {
    content: '\e96a';
  }
  &.icon-estimator:before {
    content: '\e96c';
  }
  &.icon-training-material:before {
    content: '\e96e';
  }
  &.icon-collapse:before {
    content: '\e970';
  }
  &.icon-quota:before {
    content: '\e972';
  }
  &.icon-qahelp:before {
    content: '\e973';
  }
  &.icon-compliance-signed:before {
    content: '\e974';
  }
  &.icon-compliance-notsigned:before {
    content: '\e972';
  }
  &.icon-administrator:before {
    content: '\e946';
  }
  &.icon-planactivation:before {
    content: '\e951';
  }
  &.icon-impersonate:before {
    content: '\e92c';
  }
  &.icon-graph:before {
    content: '\e917';
  }
  &.icon-vision:before {
    content: '\e900';
  }
}
