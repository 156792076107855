$font: 'Segoe UI';
$font-regular: 'Segoe UI Regular', 'Segoe UI';
$font-semilight: 'Segoe UI Semilight', 'Segoe UI Light', 'Segoe UI';
$font-light: 'Segoe UI Light', 'Segoe UI';
$icon-font: 'Segoe UI Symbol';
$font-semibold: 'Segoe UI Semibold', 'Segoe UI';
$font-bold: 'Segoe UI bold', 'Segoe UI';
$font-mdl2: 'SegoeUI MDL2 Assets';

@font-face {
  font-family: 'Segoe UI';
  src: url('/assets/fonts/black/segoeui.ttf') format('truetype'), url('/assets/fonts/black/segoeui.eot'), url('/assets/fonts/black/segoeui.woff') format('woff'),
    url('/assets/fonts/black/segoeui.svgz#SegoeUINegreta') format('svg'), url('/assets/fonts/black/segoeui.svg#SegoeUINegreta') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('/assets/fonts/light/segoeui.ttf') format('truetype'), url('/assets/fonts/light/segoeui.eot'), url('/assets/fonts/light/segoeui.woff') format('woff'),
    url('/assets/fonts/light/segoeui.svgz#SegoeUILight') format('svg'), url('/assets/fonts/light/segoeui.svg#SegoeUILight') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('/assets/fonts/regular/segoeui.ttf') format('truetype'), url('/assets/fonts/regular/segoeui.eot'), url('/assets/fonts/regular/segoeui.woff') format('woff'),
    url('/assets/fonts/regular/segoeui.svgz#SegoeUINormal') format('svg'), url('/assets/fonts/regular/segoeui.svg#SegoeUINormal') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('/assets/fonts/semibold/segoeui.ttf') format('truetype'), url('/assets/fonts/semibold/segoeui.eot'), url('/assets/fonts/semibold/segoeui.woff') format('woff'),
    url('/assets/fonts/semibold/segoeui.svgz#SegoeUISemibold') format('svg'), url('/assets/fonts/semibold/segoeui.svg#SegoeUISemibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeUI MDL2 Assets';
  src: url(/assets/fonts/SEGMDL2.TTF);
}

@font-face {
  font-family: 'ms_icon_family';
  src: url('/assets/fonts/ms_icon_family.eot?ymxww9');
  src: url('/assets/fonts/ms_icon_family.eot?ymxww9#iefix') format('embedded-opentype'), url('/assets/fonts/ms_icon_family.ttf?ymxww9') format('truetype'),
    url('/assets/fonts/ms_icon_family.woff?ymxww9') format('woff'), url('/assets/fonts/ms_icon_family.svg?ymxww9#ms_icon_family') format('svg');
  font-weight: normal;
  font-style: normal;
}
