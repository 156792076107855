@charset "UTF-8";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/black/segoeui.ttf") format("truetype"), url("/assets/fonts/black/segoeui.eot"), url("/assets/fonts/black/segoeui.woff") format("woff"), url("/assets/fonts/black/segoeui.svgz#SegoeUINegreta") format("svg"), url("/assets/fonts/black/segoeui.svg#SegoeUINegreta") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/light/segoeui.ttf") format("truetype"), url("/assets/fonts/light/segoeui.eot"), url("/assets/fonts/light/segoeui.woff") format("woff"), url("/assets/fonts/light/segoeui.svgz#SegoeUILight") format("svg"), url("/assets/fonts/light/segoeui.svg#SegoeUILight") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/regular/segoeui.ttf") format("truetype"), url("/assets/fonts/regular/segoeui.eot"), url("/assets/fonts/regular/segoeui.woff") format("woff"), url("/assets/fonts/regular/segoeui.svgz#SegoeUINormal") format("svg"), url("/assets/fonts/regular/segoeui.svg#SegoeUINormal") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/semibold/segoeui.ttf") format("truetype"), url("/assets/fonts/semibold/segoeui.eot"), url("/assets/fonts/semibold/segoeui.woff") format("woff"), url("/assets/fonts/semibold/segoeui.svgz#SegoeUISemibold") format("svg"), url("/assets/fonts/semibold/segoeui.svg#SegoeUISemibold") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "SegoeUI MDL2 Assets";
  src: url(/assets/fonts/SEGMDL2.TTF);
}
@font-face {
  font-family: "ms_icon_family";
  src: url("/assets/fonts/ms_icon_family.eot?ymxww9");
  src: url("/assets/fonts/ms_icon_family.eot?ymxww9#iefix") format("embedded-opentype"), url("/assets/fonts/ms_icon_family.ttf?ymxww9") format("truetype"), url("/assets/fonts/ms_icon_family.woff?ymxww9") format("woff"), url("/assets/fonts/ms_icon_family.svg?ymxww9#ms_icon_family") format("svg");
  font-weight: normal;
  font-style: normal;
}
/*#eb5f52;*/
/*#d54e42;*/
/*#b9443a;*/
.icon-container {
  outline: none;
  border: 0;
  width: auto;
  display: block;
  float: left;
  min-width: 0;
  min-height: 0;
  font-size: 1.4285714286em;
  padding: 0 14px;
  background-color: transparent;
}
.icon-container span {
  margin-left: 10px;
  position: relative;
  top: -3px;
  /* fix for improper positioning */
}
.icon-container:active, .icon-container:hover {
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.icon-container:active {
  background: rgba(0, 0, 0, 0.2);
  -moz-transform: scale(0.98, 0.98);
  -ms-transform: scale(0.98, 0.98);
  -o-transform: scale(0.98, 0.98);
  -webkit-transform: scale(0.98, 0.98);
  transform: scale(0.98, 0.98);
}
.icon-container.disabled {
  opacity: 0.6;
  cursor: none;
}
.icon-container.disabled:active, .icon-container.disabled:hover {
  background: transparent;
  cursor: none;
}
.icon-container.disabled:active {
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  -webkit-transform: none;
  transform: none;
}

.icon {
  font-family: "SegoeUI MDL2 Assets";
  font-weight: normal;
  font-style: normal;
  position: relative;
  top: 0;
  display: inline;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon.icon-notification:before {
  content: "";
}
.icon.icon-back:before {
  content: "";
}
.icon.icon-unlock:before {
  content: "";
}
.icon.icon-close:before {
  content: "";
}
.icon.icon-hamburger:before {
  content: "";
}
.icon.icon-search:before {
  content: "";
}
.icon.icon-user:before {
  content: "";
}
.icon.icon-settings:before {
  content: "";
}
.icon.icon-setting:before {
  content: "";
}
.icon.icon-chevron-up:before {
  content: "";
}
.icon.icon-chevron-down:before {
  content: "";
}
.icon.icon-chevron-right:before {
  content: "";
}
.icon.icon-chevron-left:before {
  content: "";
}
.icon.icon-star:before {
  content: "";
}
.icon.icon-star-filled:before {
  content: "";
}
.icon.icon-basket:before {
  content: "";
}
.icon.icon-pipe:before {
  content: "";
}
.icon.icon-accept:before {
  content: "";
}
.icon.icon-pipe-health:before {
  content: "";
}
.icon.icon-bulb:before {
  content: "";
}
.icon.icon-alert:before {
  content: "";
}
.icon.icon-eye:before {
  content: "";
}
.icon.icon-square:before {
  content: "";
}
.icon.icon-circle:before {
  content: "";
}
.icon.icon-circle-outline:before {
  content: "";
}
.icon.icon-home:before {
  content: "";
}
.icon.icon-apps:before {
  content: "";
}
.icon.icon-pointer:before {
  content: "";
}
.icon.icon-next:before {
  content: "";
}
.icon.icon-devices:before {
  content: "";
}
.icon.icon-clock:before {
  content: "";
}
.icon.icon-collapse:before {
  content: "";
}
.icon.icon-book:before {
  content: "";
}
.icon.icon-calender:before {
  content: "";
}
.icon.icon-plus:before {
  content: "";
}
.icon.icon-resize:before {
  content: "";
}
.icon.icon-glyph-81:before {
  content: "";
}
.icon.icon-glyph-70:before {
  content: "";
}
.icon.icon-help:before {
  content: "";
}
.icon.icon-arrow-bottom-right:before {
  content: "";
}
.icon.icon-file-outline-up:before {
  content: "";
}
.icon.icon-copy:before {
  content: "";
}
.icon.icon-calculator:before {
  content: "";
}
.icon.icon-shuffle:before {
  content: "";
}
.icon.icon-account-box:before {
  content: "";
}
.icon.icon-emoji:before {
  content: "";
}
.icon.icon-info:before {
  content: "";
}
.icon.icon-email-forward:before {
  content: "";
}
.icon.icon-file-outline:before {
  content: "";
}
.icon.icon-file-multiple:before {
  content: "";
}
.icon.icon-download:before {
  content: "";
}
.icon.icon-account-outline:before {
  content: "";
}
.icon.icon-gylph-74:before {
  content: "";
}
.icon.icon-account-switch:before {
  content: "";
}
.icon.icon-dots-horizontal:before {
  content: "";
}
.icon.icon-account-multiple:before {
  content: "";
}
.icon.icon-checkbox-blank:before {
  content: "";
}
.icon.icon-check:before {
  content: "";
}
.icon.icon-box-full:before {
  content: "";
}
.icon.icon-filter-close:before {
  content: "";
}
.icon.icon-full-screen:before {
  content: "";
}
.icon.icon-arrow-up:before {
  content: "";
}
.icon.icon-arrow-down:before {
  content: "";
}
.icon.icon-filter:before {
  content: "";
}
.icon.icon-rectangle:before {
  content: "";
}
.icon.icon-circle-lg:before {
  content: "";
}
.icon.icon-percent:before {
  content: "";
}
.icon.icon-grid:before {
  content: "";
}
.icon.icon-minus:before {
  content: "";
}
.icon.icon-video:before {
  content: "";
}
.icon.icon-doc:before {
  content: "";
}
.icon.icon-list-expand:before {
  content: "";
}
.icon.icon-list-collapse:before {
  content: "";
}
.icon.icon-repeat:before {
  content: "";
}
.icon.icon-refresh:before {
  content: "";
}
.icon.icon-bell-outline:before {
  content: "";
}
.icon.icon-tip:before {
  content: "";
}
.icon.icon-warning::before {
  content: "";
}
.icon.icon-pin-left:before {
  content: "";
}
.icon.icon-delete:before {
  content: "";
}
.icon.icon-edit:before {
  content: "";
}
.icon.icon-camera:before {
  content: "";
}
.icon.icon-logout:before {
  content: "";
}
.icon.icon-logout:before {
  content: "";
}
.icon.icon-trainingClicked:before {
  content: "";
  font-size: 13.5px;
}
.icon.icon-openInNewWindow:before {
  content: "";
  font-size: 13.5px;
}
.icon.icon-chatBot:before {
  content: "";
}

.icon-ms {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ms_icon_family" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-ms.icon-dashboard:before {
  content: "";
}
.icon-ms.icon-treeorg:before {
  content: "";
}
.icon-ms.icon-expand:before {
  content: "";
}
.icon-ms.icon-menu:before {
  content: "";
}
.icon-ms.icon-drag:before {
  content: "";
}
.icon-ms.icon-information:before {
  content: "";
}
.icon-ms.icon-information-hover:before {
  content: "";
}
.icon-ms.icon-estimator:before {
  content: "";
}
.icon-ms.icon-training-material:before {
  content: "";
}
.icon-ms.icon-collapse:before {
  content: "";
}
.icon-ms.icon-quota:before {
  content: "";
}
.icon-ms.icon-qahelp:before {
  content: "";
}
.icon-ms.icon-compliance-signed:before {
  content: "";
}
.icon-ms.icon-compliance-notsigned:before {
  content: "";
}
.icon-ms.icon-administrator:before {
  content: "";
}
.icon-ms.icon-planactivation:before {
  content: "";
}
.icon-ms.icon-impersonate:before {
  content: "";
}
.icon-ms.icon-graph:before {
  content: "";
}
.icon-ms.icon-vision:before {
  content: "";
}

/*#eb5f52;*/
/*#d54e42;*/
/*#b9443a;*/
app-root .mat-drawer-container {
  position: unset;
  height: -webkit-fill-available;
}

.mat-container .mat-drawer-backdrop.mat-drawer-shown {
  background-color: #fafafa;
  opacity: 0.65;
}

.mat-container .mat-drawer-content {
  z-index: 0;
}

.mat-mainContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.mat-mainContainer.inactive {
  opacity: 0.65;
}

.mat-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-color: white;
}

.content {
  padding-left: 10px;
  padding-right: 10px;
}

.header_tile {
  padding: 10px 10px 10px 10px;
  width: 100%;
  display: flex;
}
.header_tile__heading {
  text-shadow: 0 0 0 #000;
  margin-bottom: unset;
  line-height: 1.5;
  font-size: 1rem;
}
.header_tile__filter-icon {
  margin-left: auto;
  color: #0078d4;
}
.header_tile__filter-icon:focus {
  outline: 1px dashed #000;
}

/*#eb5f52;*/
/*#d54e42;*/
/*#b9443a;*/
#header {
  background-color: #26873a;
  height: 48px;
  overflow: hidden;
  color: #fff;
  display: flex;
}

.title {
  font-weight: 200;
  font-size: 18pt;
  color: #ffffff;
  display: block;
  float: left;
  line-height: 48px;
}

#mobile_title {
  font-size: 12pt;
  margin: 0px 0px 0px 19px;
  font-weight: 600;
}

.hamburger-icon-container {
  font-size: 14pt;
  height: fit-content;
  width: fit-content;
  color: #ffffff;
  line-height: 42px;
  position: relative;
  top: 2px;
  left: 1px;
  float: left;
  margin-left: 14px;
}
.hamburger-icon-container.margin-fix {
  margin-right: 20px;
}
.hamburger-icon-container:focus {
  outline: 1px dashed #fff;
  background: rgba(0, 0, 0, 0.1);
}

.avatar-container {
  margin: auto 2px auto auto;
}

.notification-container {
  margin: 10px;
}
.notification-container:focus {
  outline: 1px dashed #fff;
  background: rgba(0, 0, 0, 0.1);
}

.skip-mainContent {
  position: absolute;
  width: 1px;
  height: 1px;
  z-index: -999;
}

.skip-mainContent:focus,
.skip-mainContent:active {
  color: #fff;
  text-decoration: underline;
  width: auto;
  height: auto;
  overflow: auto;
  border-radius: 1px;
  text-align: center;
  font-size: medium;
  z-index: 999;
}

/*#eb5f52;*/
/*#d54e42;*/
/*#b9443a;*/
.sidebar {
  background: #231f20;
  color: #fff;
  width: 20%;
  min-width: 70%;
  font-family: "Segoe UI";
  left: 0;
}

.overlay {
  z-index: 9;
}

#overlayContainer {
  background: white;
}

:host {
  background: #231f20;
  color: #ffffff;
  position: fixed;
  right: auto;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20%;
  min-width: 70%;
  z-index: 9999;
  font-family: "Segoe UI";
}

#sidebar_ul {
  line-height: 1.5;
  list-style: none;
  padding: 14.5vw 0 0 0;
}
#sidebar_ul li {
  margin-bottom: 3vw;
}
#sidebar_ul li:focus {
  outline: 1px dashed #fff;
}
#sidebar_ul li > a {
  text-decoration: none;
  color: #ffffff;
}
#sidebar_ul li > a div {
  padding-left: 4.5vw;
}
#sidebar_ul li > a div > * {
  display: inline;
  vertical-align: middle;
}
#sidebar_ul li > div {
  padding-left: 4.5vw;
}
#sidebar_ul li > div > * {
  display: inline;
  vertical-align: middle;
}
#sidebar_ul span {
  margin-left: 5%;
}
#sidebar_ul em {
  vertical-align: middle;
  font-size: 14pt;
  padding-right: 4%;
}

#title {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
}

#splitter-leftnav {
  border-bottom: 1px solid lightgray;
  padding-bottom: 0px !important;
  margin-left: -10%;
  margin-bottom: 3vw;
}

#sidebarUserName {
  width: 100%;
  background-color: #26873a;
  padding-left: 3.6vw;
  font-weight: 600;
}

.activeRoute {
  border-left: 1.5vw solid #26873a;
}
.activeRoute > div {
  padding-left: 2.5vw !important;
}

#sidebar_title {
  padding: unset;
}

.sidebar .mat-drawer-inner-container {
  overflow: hidden !important;
}

@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/black/segoeui.ttf") format("truetype"), url("/assets/fonts/black/segoeui.eot"), url("/assets/fonts/black/segoeui.woff") format("woff"), url("/assets/fonts/black/segoeui.svgz#SegoeUINegreta") format("svg"), url("/assets/fonts/black/segoeui.svg#SegoeUINegreta") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/light/segoeui.ttf") format("truetype"), url("/assets/fonts/light/segoeui.eot"), url("/assets/fonts/light/segoeui.woff") format("woff"), url("/assets/fonts/light/segoeui.svgz#SegoeUILight") format("svg"), url("/assets/fonts/light/segoeui.svg#SegoeUILight") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/regular/segoeui.ttf") format("truetype"), url("/assets/fonts/regular/segoeui.eot"), url("/assets/fonts/regular/segoeui.woff") format("woff"), url("/assets/fonts/regular/segoeui.svgz#SegoeUINormal") format("svg"), url("/assets/fonts/regular/segoeui.svg#SegoeUINormal") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/semibold/segoeui.ttf") format("truetype"), url("/assets/fonts/semibold/segoeui.eot"), url("/assets/fonts/semibold/segoeui.woff") format("woff"), url("/assets/fonts/semibold/segoeui.svgz#SegoeUISemibold") format("svg"), url("/assets/fonts/semibold/segoeui.svg#SegoeUISemibold") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "SegoeUI MDL2 Assets";
  src: url(/assets/fonts/SEGMDL2.TTF);
}
@font-face {
  font-family: "ms_icon_family";
  src: url("/assets/fonts/ms_icon_family.eot?ymxww9");
  src: url("/assets/fonts/ms_icon_family.eot?ymxww9#iefix") format("embedded-opentype"), url("/assets/fonts/ms_icon_family.ttf?ymxww9") format("truetype"), url("/assets/fonts/ms_icon_family.woff?ymxww9") format("woff"), url("/assets/fonts/ms_icon_family.svg?ymxww9#ms_icon_family") format("svg");
  font-weight: normal;
  font-style: normal;
}
/*#eb5f52;*/
/*#d54e42;*/
/*#b9443a;*/
.btn {
  border-radius: 0;
  box-shadow: none;
  text-shadow: none;
  font-weight: 400;
  font-size: 12pt;
  font-family: "Segoe UI";
  padding: 0 12px;
  min-width: 90px;
  height: 30px;
  line-height: 28px;
}
.btn:active, .btn:hover, .btn:focus {
  box-shadow: none;
  text-shadow: none;
  border-radius: 0;
}
.btn:focus {
  outline: dotted #333;
}
.btn .icon {
  font-weight: 500;
}
.btn.btn-dark {
  background-color: #231f20;
  color: #ffffff;
  /*border: 1px solid $color-dark;*/
}
.btn.btn-dark.btn-sm {
  height: 30px !important;
  padding: 3px 10px !important;
}
.btn.btn-dark:not([disabled]):hover {
  background-color: transparent;
  border: 1px solid #231f20;
  color: #231f20;
}
.btn.btn-light {
  background-color: #ffffff;
  border: 1px solid #231f20;
  color: #333333;
}
.btn.btn-light:not([disabled]):hover {
  background-color: #231f20;
  color: #ffffff;
}
.btn.btn-dark-light {
  background-color: #231f20;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.btn.btn-ico {
  background-color: #ededed;
  color: #333333;
  width: 35px;
  padding-left: 10px !important;
}
.btn.btn-icon {
  background-color: transparent;
  color: inherit;
  padding: 0;
  margin: 0;
  min-width: auto;
}
.btn.btn-report {
  background-color: #231f20;
  font-size: 10px;
  margin-top: -4px;
  border: solid #ffffff;
  color: #ffffff;
  padding: 0;
  height: 144%;
  line-height: 2%;
  border-bottom-width: thin;
  border-left-width: thin;
  border-right-width: thin;
  border-top-width: thin;
}
.btn.dropdown-toggle {
  border: none;
}

.mat-icon-container {
  background: unset;
  border: unset;
  height: max-content;
  padding: unset;
}
.mat-icon-container:focus {
  outline: none;
}

.action-btn {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #0078d4;
  color: #ffffff;
  filter: drop-shadow(0px 0.5px 0.5px rgba(0, 0, 0, 0.25));
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0px rgba(0, 0, 0, 0.1) inset;
}

.mint-mobile-modal-fullscreen .modal-dialog {
  margin: unset;
  height: 100%;
  background: white;
}

.mint-mobile-modal-fullscreen .modal-dialog .modal-content {
  border: unset;
}

.slick-slider {
  margin-bottom: 0;
  width: 100%;
  margin: 0;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #707070;
  outline: none;
  background: transparent;
}

.slick-prev:focus,
.slick-next:focus {
  outline: #4d90fe auto 1px;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "SegoeUI MDL2 Assets";
  font-weight: bold;
  position: relative;
  font-size: 16px;
  opacity: 0.75;
  color: #707070;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
  transform: translate(0);
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "";
}

[dir=rtl] .slick-prev:before {
  content: "";
}

.slick-next {
  right: -25px;
  transform: translate(0);
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "";
}

[dir=rtl] .slick-next:before {
  content: "";
}

.slick-track {
  margin: 0;
}

.carousel_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.carousel_container_rect {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin-right: 10px;
}

.rect_active {
  background-color: #2f80ed;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.pcg__item__metric.below-threshold {
  border: 1px solid #eb5757;
}
.pcg__item__metric.below-threshold .metric__weight__val {
  border: 1px solid #eb5757;
}
.pcg__item__metric.above-threshold {
  border: 1px solid #d77f00;
}
.pcg__item__metric.above-threshold .metric__weight__val {
  border: 1px solid #d77f00;
}
.pcg__item__metric.above-target {
  border: 1px solid #27ae60;
}
.pcg__item__metric.above-target .metric__weight__val {
  border: 1px solid #27ae60;
}
.pcg__item__metric.above-excellence {
  border: 1px solid #2f80ed;
}
.pcg__item__metric.above-excellence .metric__weight__val {
  border: 1px solid #2f80ed;
}
.pcg__item__metric.neutral {
  border: 1px solid #27ae60;
}
.pcg__item__metric.neutral .metric__weight__val {
  border: 1px solid #27ae60;
}
.pcg__item__metric.rubi_neutral {
  border: 1px solid #000000;
}
.pcg__item__metric.rubi_neutral .metric__weight__val {
  border: 1px solid #000000;
}
.pcg__item__metric.ocp_metric {
  border: 1px solid #999999;
}
.pcg__item__metric.ocp_metric .metric__weight__val {
  border: 1px solid #999999;
}

.pcg__item__metric__top__border__below-threshold {
  height: 8px;
  background-color: #eb5757;
}
.pcg__item__metric__top__border__above-threshold {
  height: 8px;
  background-color: #ffb900;
  border: 1px solid #d77f00;
}
.pcg__item__metric__top__border__above-target {
  height: 8px;
  background-color: #27ae60;
}
.pcg__item__metric__top__border__above-excellence {
  height: 8px;
  background-color: #2f80ed;
}
.pcg__item__metric__top__border__neutral {
  height: 8px;
  background-color: #27ae60;
}
.pcg__item__metric__top__border__rubi_neutral {
  height: 8px;
  background-color: #000000;
}
.pcg__item__metric__top__border__ocp_metric {
  height: 8px;
  background-color: #999999;
}

.mat-progress-bar.below-threshold .mat-progress-bar-fill::after {
  background-color: #eb5757 !important;
}

.mat-progress-bar.above-threshold .mat-progress-bar-fill::after {
  background-color: #ffb900 !important;
  border: 1px solid #d77f00;
}

.mat-progress-bar.above-target .mat-progress-bar-fill::after {
  background-color: #27ae60 !important;
}

.mat-progress-bar.above-excellence .mat-progress-bar-fill::after {
  background-color: #2f80ed !important;
}

.mat-progress-bar.rubi_neutral .mat-progress-bar-fill::after {
  background-color: #000000 !important;
}

.legend__item__clr__legend1 {
  background-color: #eb5757;
}
.legend__item__clr__legend2 {
  background-color: #ffb900;
  border: 1px solid #d77f00;
}
.legend__item__clr__legend3 {
  background-color: #27ae60;
}
.legend__item__clr__legend4 {
  background-color: #2f80ed;
}
.legend__item__clr__legend5 {
  background-color: #999999;
}

.legend {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.legend__item {
  display: flex;
  align-items: center;
}
.legend__item:not(:last-child) {
  margin-right: 20px;
}
.legend__item__clr {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  flex-shrink: 0;
}
.legend__item__icon {
  width: 20px;
  font-size: 20px;
  margin-right: 5px;
}
.legend__item__text {
  font-size: 11px;
  color: #4f4f4f;
}

/*#eb5f52;*/
/*#d54e42;*/
/*#b9443a;*/
.tabs {
  height: max-content;
  display: flex;
  width: 100%;
  box-shadow: 0px 2px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
}

.tile-tabset {
  width: 100%;
  text-align: center;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 3.2vw;
}

.tab {
  padding: 16px;
}
.tab__header {
  font-size: 16px;
  font-weight: bold;
}

.nav-tabs {
  display: flex;
}

.nav-tabs .nav-item {
  flex-grow: 0;
  flex-basis: 50%;
}
.nav-tabs .nav-item:first-child {
  border-right: 1px solid #ededed;
}

.nav-tabs .nav-link {
  border-radius: 0;
  color: #707070;
}
.nav-tabs .nav-link:focus {
  outline: 1px dashed #000000;
}

.nav-tabs a.nav-link.active,
.nav-tabs .show > a.nav-link {
  background-color: #fff;
  color: black;
  border-bottom: 2px solid #0078d4;
  opacity: 1;
  height: 100%;
}
.nav-tabs a.nav-link.active:focus,
.nav-tabs .show > a.nav-link:focus {
  outline: 1px dashed #000000;
}

html,
body {
  height: 100%;
  font-family: "Segoe UI";
}

*:focus {
  outline: none;
}