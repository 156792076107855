@import '../base/colors';

app-root .mat-drawer-container {
  position: unset;
  height: -webkit-fill-available;
}

.mat-container .mat-drawer-backdrop.mat-drawer-shown {
  background-color: #fafafa;
  opacity: 0.65;
}

.mat-container .mat-drawer-content {
  z-index: 0;
}
.mat-mainContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &.inactive {
    opacity: 0.65;
  }
}

.mat-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-color: white;
}

.content {
  padding-left: 10px;
  padding-right: 10px;
}

.header_tile {
  padding: 10px 10px 10px 10px;
  width: 100%;
  display: flex;

  &__heading {
    text-shadow: 0 0 0 #000;
    margin-bottom: unset;
    line-height: 1.5;
    font-size: 1rem;
  }

  &__filter-icon {
    margin-left: auto;
    color: $color-input-primary;

    &:focus {
      outline: 1px dashed #000;
    }
  }
}
