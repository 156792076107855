.slick-slider {
  margin-bottom: 0;
  width: 100%;
  margin: 0;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: $color-text-secondary;
  outline: none;
  background: transparent;
}

.slick-prev:focus,
.slick-next:focus {
  outline: #4d90fe auto 1px;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'SegoeUI MDL2 Assets';
  font-weight: bold;
  position: relative;
  font-size: 16px;
  opacity: 0.75;
  color: $color-text-secondary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
  transform: translate(0);
}

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: '\E00E';
}

[dir='rtl'] .slick-prev:before {
  content: '\E00E';
}

.slick-next {
  right: -25px;
  transform: translate(0);
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: '\E00F';
}

[dir='rtl'] .slick-next:before {
  content: '\E00F';
}

.slick-track {
  margin: 0;
}

.carousel_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  &_rect {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #e0e0e0;
    margin-right: 10px;
  }
}

.rect_active {
  background-color: #2f80ed;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
