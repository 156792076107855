$BelowThreshold: #eb5757;
$AboveThreshold: #ffb900;
$AboveThresholdBorder: 1px solid #d77f00;
$AboveThresholdBorderColor: #d77f00;
$AboveTarget: #27ae60;
$Old: #707070;
$AboveExcellence: #2f80ed;
$Neutral: #27ae60;
$RUBINeutral: #000000;
$CompleteStatus: #27ae60;
$OCPMetric: #999999;
