@import '../base/colors';

.sidebar {
  background: #231f20;
  color: #fff;
  width: 20%;
  min-width: 70%;
  font-family: 'Segoe UI';
  left: 0;
}

.overlay {
  z-index: 9;
}

#overlayContainer {
  background: white;
}

:host {
  background: #231f20;
  color: $header-foreground;
  position: fixed;
  right: auto;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20%;
  min-width: 70%;
  z-index: 9999;
  font-family: 'Segoe UI';
}

#sidebar_ul {
  line-height: 1.5;
  list-style: none;
  padding: 14.5vw 0 0 0;

  li {
    margin-bottom: 3vw;

    &:focus {
      outline: 1px dashed #fff;
    }

    & > a {
      text-decoration: none;
      color: $header-foreground;

      div {
        padding-left: 4.5vw;

        & > * {
          display: inline;
          vertical-align: middle;
        }
      }
    }

    & > div {
      padding-left: 4.5vw;
    }

    & > div > * {
      display: inline;
      vertical-align: middle;
    }
  }

  span {
    margin-left: 5%;
  }
  em {
    vertical-align: middle;
    font-size: 14pt;
    padding-right: 4%;
  }
}

#title {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
}

#splitter-leftnav {
  border-bottom: 1px solid lightgray;
  padding-bottom: 0px !important;
  margin-left: -10%;
  margin-bottom: 3vw;
}

#sidebarUserName {
  width: 100%;
  background-color: $header-background;
  padding-left: 3.6vw;
  font-weight: 600;
}

.activeRoute {
  border-left: 1.5vw solid $header-background;
  & > div {
    padding-left: 2.5vw !important;
  }
}

#sidebar_title {
  padding: unset;
}

.sidebar .mat-drawer-inner-container {
  overflow: hidden !important;
}
