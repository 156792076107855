@function em($px, $base: $base-font-size) {
  @return ($px / $base) * 1em;
}

@mixin focus-states($color: null) {
  @if ($color) {
    &:active,
    &:hover {
      background: $color;
      cursor: pointer;
    }

    &:active {
      background: $color;
      -moz-transform: scale(0.98, 0.98);
      -ms-transform: scale(0.98, 0.98);
      -o-transform: scale(0.98, 0.98);
      -webkit-transform: scale(0.98, 0.98);
      transform: scale(0.98, 0.98);
    }

    &.disabled {
      opacity: 0.6;

      &:active,
      &:hover {
        background: transparent;
      }

      &:active {
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        -webkit-transform: none;
        transform: none;
      }
    }
  } @else {
    &:active,
    &:hover {
      background: rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    &:active {
      background: rgba(0, 0, 0, 0.2);
      -moz-transform: scale(0.98, 0.98);
      -ms-transform: scale(0.98, 0.98);
      -o-transform: scale(0.98, 0.98);
      -webkit-transform: scale(0.98, 0.98);
      transform: scale(0.98, 0.98);
    }

    &.disabled {
      opacity: 0.6;
      cursor: none;

      &:active,
      &:hover {
        background: transparent;
        cursor: none;
      }

      &:active {
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        -webkit-transform: none;
        transform: none;
      }
    }
  }
}
$break-mob: 320px;
$break-tablet: 768px;
$break-desktop: 1024px;
$break-medium: 1200px;
$break-large: 1920px;

@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-mob) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $break-tablet) and (max-width: $break-desktop - 1) {
      @content;
    }
  } @else if $media == mobile {
    @media only screen and (min-width: $break-mob) and (max-width: $break-tablet - 1) {
      @content;
    }
  } @else if $media == medium-screens {
    @media only screen and (min-width: $break-mob + 1) and (max-width: $break-desktop - 1) {
      @content;
    }
  } @else if $media == wide-screens {
    @media only screen and (min-width: $break-desktop) {
      @content;
    }
  } @else if $media == medium {
    @media only screen and (min-width: $break-medium) {
      @content;
    }
  } @else if $media == large-screens {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  }
}
