// card styles
.pcg__item__metric {
  &.below-threshold {
    border: 1px solid $BelowThreshold;
    .metric__weight__val {
      border: 1px solid $BelowThreshold;
    }
  }
  &.above-threshold {
    border: 1px solid $AboveThresholdBorderColor; //to achieve luminosity > 3 Accessibility
    .metric__weight__val {
      border: 1px solid $AboveThresholdBorderColor;
    }
  }
  &.above-target {
    border: 1px solid $AboveTarget;
    .metric__weight__val {
      border: 1px solid $AboveTarget;
    }
  }
  &.above-excellence {
    border: 1px solid $AboveExcellence;
    .metric__weight__val {
      border: 1px solid $AboveExcellence;
    }
  }
  &.neutral {
    border: 1px solid $Neutral;
    .metric__weight__val {
      border: 1px solid $Neutral;
    }
  }
  &.rubi_neutral {
    border: 1px solid $RUBINeutral;
    .metric__weight__val {
      border: 1px solid $RUBINeutral;
    }
  }
  &.ocp_metric {
    border: 1px solid $OCPMetric;
    .metric__weight__val {
      border: 1px solid $OCPMetric;
    }
  }
}

// PCG top border for accessibility

.pcg__item__metric__top__border__ {
  &below-threshold {
    height: 8px;
    background-color: $BelowThreshold;
  }
  &above-threshold {
    height: 8px;
    background-color: $AboveThreshold;
    border: 1px solid $AboveThresholdBorderColor;
  }
  &above-target {
    height: 8px;
    background-color: $AboveTarget;
  }
  &above-excellence {
    height: 8px;
    background-color: $AboveExcellence;
  }
  &neutral {
    height: 8px;
    background-color: $Neutral;
  }
  &rubi_neutral {
    height: 8px;
    background-color: $RUBINeutral;
  }
  &ocp_metric {
    height: 8px;
    background-color: $OCPMetric;
  }
}

.mat-progress-bar.below-threshold .mat-progress-bar-fill::after {
  background-color: $BelowThreshold !important;
}

.mat-progress-bar.above-threshold .mat-progress-bar-fill::after {
  background-color: $AboveThreshold !important;
  border: $AboveThresholdBorder;
}

.mat-progress-bar.above-target .mat-progress-bar-fill::after {
  background-color: $AboveTarget !important;
}

.mat-progress-bar.above-excellence .mat-progress-bar-fill::after {
  background-color: $AboveExcellence !important;
}

.mat-progress-bar.rubi_neutral .mat-progress-bar-fill::after {
  background-color: $RUBINeutral !important;
}

.legend__item__clr {
  &__legend1 {
    background-color: $BelowThreshold;
  }

  &__legend2 {
    background-color: $AboveThreshold;
    border: $AboveThresholdBorder;
  }

  &__legend3 {
    background-color: $AboveTarget;
  }

  &__legend4 {
    background-color: $AboveExcellence;
  }

  &__legend5 {
    background-color: $OCPMetric;
  }
}
.legend {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &__clr {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      flex-shrink: 0;
    }

    &__icon {
      width: 20px;
      font-size: 20px;
      margin-right: 5px;
    }

    &__text {
      font-size: 11px;
      color: $color-text-tertiary;
    }
  }
}
